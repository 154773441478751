// media size
$mobileWidth: 767px;
$desktopWidth: 1440px;

@mixin media-breakpoint-up($size) {
  @if $size == sm {
    @media (min-width: 576px) {
      @content;
    }
  } @else if $size == md {
    @media (min-width: 768px) {
      @content;
    }
  } @else if $size == lg {
    @media (min-width: 992px) {
      @content;
    }
  } @else if $size == xl {
    @media (min-width: 1200px) {
      @content;
    }
  }
}

@mixin media-breakpoint-down($size) {
  @if $size == xs {
    @media (max-width: 575.98px) {
      @content;
    }
  }
  @if $size == sm {
    @media (max-width: 767.98px) {
      @content;
    }
  } @else if $size == md {
    @media (max-width: 991.98px) {
      @content;
    }
  } @else if $size == tablet {
    @media (max-width: 1024px) {
      @content;
    }
  } @else if $size == lg {
    @media (max-width: 1199.98px) {
      @content;
    }
  }
}
